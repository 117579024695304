import * as React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/Layout/Layout";
import Events from "../components/Events/Events";

const EventsPage = (props) => {
  const { allContentfulEventPage: eventsData } = props.data;

  return (
    <Layout vertical={true}>
      <Seo title="Events" />
      <Events events={eventsData.nodes} />
    </Layout>
  );
};

export default EventsPage;

export const pageQuery = graphql`
  query EventPages {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulEventPage {
      nodes {
        updatedAt
        title
        featured
        slug
        highlightImage {
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
  }
`;
