import React, { useEffect, useState } from "react";
import { Callout, StyledTitle } from "../../styles/StyledComponents";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import FeaturedEventsCard from "./FeaturedEventsCard";
import Button from "../Button/Button";
import dayjs from "dayjs";

export interface EventType {
  title: string;
  featured: boolean;
  slug: string;
  updatedAt: string;
  highlightImage: {
    file: {
      url: string;
      fileName: string;
      contentType: string;
    };
  };
}

const EventCardContainer = styled.div`
  display: grid;
  row-gap: 25px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 35px;
    column-gap: 25px;
  }

  @media only screen and (max-width: 478px) {
    grid-template-columns: 1fr;
  }
`;

const EventsCardsWrapper = styled(Callout)`
  padding-top: 0px;
  padding-left: 150px;
  padding-right: 150px;
  display: grid;
  grid-template-columns: e1fr;
  column-gap: 40px;
  row-gap: 40px;

  align-items: stretch;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 10px 20px;
    margin-bottom: 40px;
    justify-content: center;
  }
`;

const EventPageTitle = styled(StyledTitle)`
  font-family: "Lato";
  color: ${colors.lightBlue};
  border-bottom: 5px solid ${colors.orange};
  padding-bottom: 20px;
  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 0px;
  }

  @media only screen and (max-width: 478px) {
  }
`;

const EventsPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationControls = styled(Button)`
  margin: 20px 10px;
  cursor: pointer;
`;

const Events = ({ events }: { events: EventType[] }) => {
  const pageSize = 5;
  const [page, setPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    const newNumPages = Math.ceil(events.length / pageSize);
    setNumPages(newNumPages);
  }, [events]);

  const nextPage = () => {
    const nextPageNum = page + 1 > numPages ? 1 : page + 1;
    setPage(nextPageNum);
  };

  const prevPage = () => {
    const prevPageNum = page - 1 < 1 ? numPages : page - 1;
    setPage(prevPageNum);
  };

  //const featuredEvent = events.filter((e) => e.featured)[0];
  const filteredEvents = events
    .sort((a, b) => {
      return dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt)) ? 1 : -1;
    })
    .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

  return (
    <>
      <Callout type="white">
        <EventPageTitle>EVENTS</EventPageTitle>
      </Callout>
      <EventsCardsWrapper type="white">
        <EventCardContainer>
          {filteredEvents.map((event, i) => (
            <FeaturedEventsCard key={i} featuredEvent={event} />
          ))}
        </EventCardContainer>
      </EventsCardsWrapper>
      {numPages > 1 && (
        <EventsPaginationWrapper>
          <PaginationControls onClick={prevPage}>Prev page</PaginationControls>
          <span>{`${page}/${numPages}`}</span>
          <PaginationControls onClick={nextPage}>Next page</PaginationControls>
        </EventsPaginationWrapper>
      )}
    </>
  );
};

export default Events;
