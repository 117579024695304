import React from "react";
import { EventType } from "./Events";
import {
  FeaturedContainer,
  FeaturedLink,
  FeaturedImage,
  FeaturedButton,
  FeaturedContent,
  FeaturedDescription,
} from "../../styles/CardComponents";
import dayjs from "dayjs";

const FeaturedEventsCard = ({
  featuredEvent,
}: {
  featuredEvent: EventType;
}) => {
  const location = `/events/${featuredEvent.slug}`;
  return (
    <FeaturedContainer>
      <FeaturedLink href={location} target="__blank">
        <FeaturedImage
          src={`${featuredEvent.highlightImage.file.url}?h=${600}`}
        ></FeaturedImage>
      </FeaturedLink>
      <FeaturedContent>
        <FeaturedLink target="__blank" href={location}>
          <span>{featuredEvent.title}</span>
        </FeaturedLink>
        <FeaturedDescription>{`Updated on: ${dayjs(
          featuredEvent.updatedAt
        ).format("MMMM D, YYYY")}`}</FeaturedDescription>
        <div style={{ marginTop: "25px" }}>
          <FeaturedButton href={location} target="__blank">
            Read More
          </FeaturedButton>
        </div>
      </FeaturedContent>
    </FeaturedContainer>
  );
};

export default FeaturedEventsCard;
